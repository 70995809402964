<template>
  <div>
    <!-- select 2 demo -->

    <b-modal
      id="modal-login"
      centered
      ok-only
      size="md"
      hide-footer="true"
      title="Add Banner"
    >
      <b-form>
        <b-form-group
          ref="image"
          label="Image"
          invalid-feedback="Image is required."
        >
          <b-media no-body>
            <div v-if="logoloading == true">
              <b-spinner
                variant="primary"
                type="grow"
                class="m-5"
                label="Spinning"
              ></b-spinner>
            </div>

            <b-media-aside>
              <b-link>
                <b-img
                  ref="previewEl"
                  rounded
                  src="https://dk0pm9zdlq16s.cloudfront.net/5be0a5b2-5eaf-4090-8528-4be800d41de0.jpg"
                  @click="$refs.fileProfile.click()"
                  v-if="logoloading == false"
                  height="250px" width="460px"
                />

                <!-- <img v-if= "loading==false" rounded :src="require('@/assets/images/elements/Unicorn-Cake.jpg')" @click="$refs.files.click()" style="width: 7vw; height: 7vw; padding-left:20px; padding-top:20px;" alt="imgAdd"/>  -->
              </b-link>

              <b-link v-if="logoloading == 'loaded'">
                <div class="text-center">
                  <i
                    @click="deleteLogo()"
                    style="margin-top: 120px"
                    class="fa fa-trash fa-md text-danger position-absolute"
                  ></i>
                </div>
                <b-img rounded :src="plus"   height="250px" width="460px" />
                <br />
              </b-link>

              <input
                type="file"
                id="fileProfile"
                hidden
                ref="fileProfile"
                @change="selectlogo()"
                accept="image/*"
              />
            </b-media-aside>
          </b-media>
          <small class="text-muted" >Recommended pixels are 850 x 400</small>
        </b-form-group>

           <b-form-group
          label="Category"
        >
          <v-select
            v-model="myObj.categoryId"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            :options="categories"
            :clearable="false"
            ref="category"
            :reduce="(val) => val.id"
            label="name"
            :disabled="dataloading"
            placeholder="Select category."
          />
        </b-form-group>
<!-- 
        <b-form-group
          label="Description"
          invalid-feedback="Description is required."
          ref="description"
        >
          <b-form-textarea
            id="textarea-default"
            ref="description"
            placeholder="Banner description."
            rows="3"
            @focusout="CheckDesc()"
            v-model="myObj.desc"
          />
        </b-form-group> -->
      </b-form>
      <br />
      <div style="">
        <b-button
          class="float-right ml-5"
          variant="primary"
          @click="AddFlavour()"
        >
          <span class="text-nowrap">Save</span>
        </b-button>
      </div>
    </b-modal>

    <div class="m-2">
      <!-- Table Top -->
      <b-row>
        <!-- Add Flavour -->
        <b-col cols="12" md="6">
          <div class="d-flex align-items-center justify-content-start">
            <div style="padding-left: 15px">
              <b-button @click="OpenAddModal()" variant="primary">
                <feather-icon icon="PlusIcon" class="mr-50" />
                <span class="text-nowrap">Add Banner</span>
              </b-button>
            </div>
            <!-- <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              /> -->
          </div>
        </b-col>
      </b-row>
    </div>
    <b-row v-for="i in items" :key="i.id">
      <b-col cols="1"></b-col>
      <b-col class="" cols="9">
        <a  @click="OpenEditModal(i)">
<b-card
         
          border-variant="light"
          img-alt="Card image cap"
          img-height="200px"
          no-body
        >
          <!-- :img-src="require('@/assets/images/slider/04.jpg')" -->

          <b-link>
            <div class="text-right">
              <b-button
              v-on:click.stop
              @click="DeleteFlavour(i.id)"
                v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                variant="danger"
                style="margin-left: -50px"
                class="
                  mt-1
                  text-danger
                  position-absolute
                  btn-icon
                  rounded-circle
                "
              >
                <feather-icon icon="Trash2Icon" />
              </b-button>
              <i ></i>
            </div>
            <b-card-img top height="260px" :src="i.image"> </b-card-img>
            <br />
          </b-link>

          <b-card-body>
            <b-card-title>
              <strong> {{ i.category }} </strong>
            </b-card-title>
            <!-- <b-card-text>
              {{ i.desc }}
            </b-card-text> -->
          </b-card-body>
        </b-card>
        </a>
        

      </b-col>
      <b-col cols="1"></b-col>

    </b-row>

    <div>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <!-- <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span> -->
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalOrders"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BForm,
  BFormInput,
  BButton,
  BTable,
  BMedia,
  BAvatar,
  BLink,
  BMediaAside,
  BSpinner,
  BFormGroup,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  BImg,
  VBTooltip,
  BCardText,
  BCardImg,
  BCardTitle,
  BCardBody,
  BFormTextarea,
} from 'bootstrap-vue';
import vSelect from 'vue-select';
import store from '@/store';
//import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter';
//import useAllOrdersList from './useAllOrdersList'
//import orderStoreModule from '../allordersStoreModule'
import flatPickr from 'vue-flatpickr-component';
import { BFormSelect } from 'bootstrap-vue';
//import Multiselect from "vue-multiselect";
import VueGallery from 'vue-gallery';
import Ripple from 'vue-ripple-directive';

export default {
  components: {
    //Multiselect,
   // BCardText,
    BCardTitle,
    BCard,
    BRow,
    BForm,
    BFormGroup,
    BCol,
    //BFormInput,
    BButton,
    //BTable,
    BMedia,
    BCardImg,
    BMediaAside,
    BSpinner,
    BCardBody,
    BImg,
    BLink,
    //BFormTextarea,
    //BMedia,
    //BAvatar,
    //BLink,
    //BBadge,
    //BDropdown,
    //BDropdownItem,
    BPagination,
    vSelect,
    //flatPickr,
    //'gallery': VueGallery
  },
  props: {
    /* modalFilter: {
    type: [String, null],
    default: null,
    },
    modalFilterOptions: {
    type: Array,
    required: true,
    }*/
  },
  directives: {
    'b-tooltip': VBTooltip,
    Ripple,
  },
  created() {
    this.LoadData();
  },
  data() {
    return {
      categories:[],
      plus: 'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png',
      fileProfile: '',
      logoloading: false,
      errors: {
        status: false,
      },
      statusOptions: ['Active', 'Inactive'],
      filterData: '',
      date: {
        range: '',
      },
      index: null,
      cover: '',
      docs: [],
      partners: [],
      fields: ['#', { label: 'Category', key: 'Name' }, 'actions'],
      items: [],
      selected: null,
      filterOptions: [
        { text: 'Most sold product', value: 'mostSold' },
        { text: 'Least sold product', value: 'leastSold' },
        { text: 'Most popular product', value: 'mostPopular' },
        { text: 'Least popular product', value: 'leastPopular' },
        { text: 'Most profitable product', value: 'mostProfitable' },
      ],
      rangeDate: null,
      myObj: {
        id: 0,
        image: '',
        categoryId: 0,
      },
      detailObj: {},
    };
  },
  methods: {
    CheckDesc() {
      var elem = this.$refs['description'];
      if (this.myObj.desc == '' || this.myObj.desc.length < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckName() {
      var elem = this.$refs['title'];
      if (this.myObj.title == '' || this.myObj.title.length < 3) {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    CheckImage() {
      var elem = this.$refs['image'];
      if (this.myObj.image == '') {
        return (elem.state = false);
      } else {
        return (elem.state = true);
      }
    },
    OpenAddModal() {
      this.$bvModal.show('modal-login');
      this.myObj.id = 0;
      this.myObj.image = '';
      this.myObj.categoryId = 0;
      this.logoloading = false;
      this.plus =
        'https://appick.io/u/lessons/64a03015-aaab-44a2-85c7-fc83ad9417b7.png';
    },
    OpenEditModal(row) {
      this.$bvModal.show('modal-login');
      this.myObj.id = row.id;
      this.myObj.image = row.image;
      this.myObj.categoryId = row.categoryId;
      this.plus = row.image;
      this.logoloading = 'loaded';
      console.log(this.plus);
    },
    DeleteFlavour(id) {
      Swal.fire({
        title: 'Are you sure?',
        text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yes, Delete it!',
      }).then((result) => {
        if (result.isConfirmed) {
          var axios = require('axios');
          var config = {
            method: 'delete',
            url:
              'https://brandaway.fastech.pk/api/Banners/' + id,
            headers: {
              Authorization:
                'bearer ' + this.$store.state.userData.token,
            },
          };    
          console.log(config.url);
          axios(config)
            .then((response) => {
              console.log(JSON.stringify(response.data));
              if (response.data.status === 'success') {
                Swal.fire(
                  'Success!',
                  'Banner has been deleted.',
                  'success',
                ).then((res) => {
                  this.LoadData();
                });
              }
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      });
    },
    selectlogo() {
      this.fileProfile = this.$refs.fileProfile.files[0];
      var axios = require('axios');
      //console.log(this.fileProfile.name);

      //console.log(this.fileProfile, this.loading );
      //Upload cover
      if (this.fileProfile !== '') {
        this.logoloading = true;
        let formData = new FormData();
        formData.append('file', this.fileProfile);
        console.log(this.fileProfile);
        axios
          .post('https://upload.appick.io', formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })

          .then((e) => {
            console.log(e.data);
            // this.file = undefined;
            var fn1 = e.data.myresp[0].path;
            console.log(fn1);
            // console.log(fn);
            this.plus = fn1;
            this.myObj.image = this.plus;
            //
            console.log(this.plus);
            //console.log(this.myObj.coverImg);
            this.logoloading = 'loaded';

            console.log(this.loading);
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    deleteLogo() {
      //console.log(0);
      this.myObj.image = '';
      this.logoloading = false;
    },
    AddFlavour() {
      this.CheckImage();

      if (
        this.CheckImage() == false
      ) {
        return this.$bvToast.toast('Please fill the form correctly.', {
          title: 'Error!',
          variant: 'danger',
          toaster: 'b-toaster-bottom-center',
        });
      } else {
        console.log(this.myObj);
        if (this.myObj.id == 0) {
          var axios = require('axios');
          // this.$store.state.userData.userID
          var config = {
            method: 'post',
            url: 'https://brandaway.fastech.pk/api/Banners',
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === 'success') {
                console.log(response.data);
                this.$bvModal.hide('modal-login');
                this.LoadData();

                this.$bvToast.toast('Banner added.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function (error) {
              console.log(error);
            });
        } else {
          //Edit
          var axios = require('axios');
          // this.$store.state.userData.userID
          var config = {
            method: 'put',
            url: 'https://brandaway.fastech.pk/api/Banners/' + this.myObj.id,
            headers: {
              Authorization: 'bearer ' + this.$store.state.userData.token,
            },
            data: this.myObj,
          };

          axios(config)
            .then((response) => {
              if (response.data.status === 'success') {
                this.$bvModal.hide('modal-login');
                console.log(response.data);
                this.LoadData();

                this.$bvToast.toast('Banner updated.', {
                  title: 'Success!',
                  variant: 'success',
                  toaster: 'b-toaster-bottom-center',
                });
              } else {
                this.$bvToast.toast('Something went wrong.', {
                  title: 'Error!',
                  variant: 'danger',
                  toaster: 'b-toaster-bottom-center',
                });
              }

              //  this.data  =  response.data;
              //console.log(this.$store.state.userData.userID);
              //this.items = response.data;

              // console.log(this.items);
            })
            .catch(function (error) {
              console.log(error);
            });
        }
      }
    },

    LoadData() {
      var axios = require('axios');
      // this.$store.state.userData.userID
      var config = {
        method: 'get',
        url: 'https://brandaway.fastech.pk/api/Banners/GetAllBanners/',
        headers: {
          Authorization: 'bearer ' + this.$store.state.userData.token,
        },
      };

      axios(config)
        .then((response) => {
          // console.log(JSON.stringify(response.data));

          //  this.data  =  response.data;
          //console.log(this.$store.state.userData.userID);
          this.items = response.data.banners;
          this.categories = response.data.categories;

          console.log(this.items);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style scoped>
.image {
  float: left;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border: 1px solid #ebebeb;
  margin: 5px;
}
</style>
